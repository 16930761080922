::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ql-font-sans-serif,
.ql-font span[data-value='sans-serif']::before {
  font-family: sans-serif;
}

.ql-font-monospace,
.ql-font span[data-value='monospace']::before {
  font-family: monospace, sans-serif;
}

.ql-font-serif,
.ql-font span[data-value='serif']::before {
  font-family: serif, sans-serif !important;
}

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}

.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}

.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}

.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}

.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

.ql-size-huge {
  font-size: larger;
}

.ql-size-large {
  font-size: large;
}

.ql-size-normal {
  font-size: medium;
}

.ql-size-small {
  font-size: small;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-align-justify {
  text-align: justify;
}