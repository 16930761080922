@font-face {
  font-family: 'Nexa-bold';
  src: url('./Assets/fonts/Nexa/NexaBold.eot');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nexa-bold';
  src: url('./Assets/fonts/Nexa/NexaBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa-light';
  src: url('./Assets/fonts/Nexa/NexaLight.eot');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Nexa-light';
  src: url('./Assets/fonts/Nexa/NexaLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
